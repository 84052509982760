<script>

import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement );

export default {
  name: "PieChart",

  components: { Pie },

  props: {

    locations: {
      type: Array,
      required: true,
    },

    slots: {
      type: Array,
      required: true,
    },

  },

  mounted() {

    this.updateChartData();

  },

  data() {

    return {

      data: [0, 0, 0],
      dataColors: ["#FFD54F", "#3F51B5", "#9C27B0"], /* "amber-lighten-2", "indigo", and "purple" from Material design color palette of Vuetify */

      chartOptions: {

        hoverBackgroundColor: "#78909C", /* "blue-grey-lighten-1" from Material design color palette of Vuetify */

        animations: {

          onComplete: () => {
            delayed = true;
          },

          delay: (context) => {
            let delay = 0;
            if (context.type === "data" && context.mode === "default" && !this.delayed) {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },

        },

        plugins: {

          legend: {
            display: false,
          }

        }

      },

    }

  },

  computed: {

    computedChartData() {
      const filteredData = [];
      const filteredDataColors = [];

      for (let i = 0; i < this.slots.length; i++) {
        filteredData.push(this.data[this.slots[i]]);
        filteredDataColors.push(this.dataColors[this.slots[i]]);
      }

      return {
        labels: ["Filled With Full Cylinders", "Filled With Empty Cylinders", "Empty"],
        datasets: [
          {
            data: filteredData,
            backgroundColor: filteredDataColors,
            borderRadius: 0,
            borderWidth: 0,
          },
        ],
      };
    },

    filledWithFullCylinders() {

      let filledWithFullCylinders = 0;

      this.locations.forEach((location) => {
        filledWithFullCylinders += location.filled_full;
      });

      return filledWithFullCylinders;

    },

    filledWithEmptyCylinders() {

      let filledWithEmptyCylinders = 0;

      this.locations.forEach((location) => {
        filledWithEmptyCylinders += location.filled_empty;
      });

      return filledWithEmptyCylinders;

    },

    empty() {

      let empty = 0;

      this.locations.forEach((location) => {
        empty += location.empty;
      });

      return empty;

    },

  },

  methods: {

    updateChartData() {

      this.data[0] = this.filledWithFullCylinders;
      this.data[1] = this.filledWithEmptyCylinders;
      this.data[2] = this.empty;

    },

  },

  watch: {

    locations() {
      this.updateChartData();
    },

  },

}

</script>

<template>

  <div class="pie-chart-container">
    <Pie :data="computedChartData" :options="chartOptions" />
  </div>

</template>
