<script>

export default {

  props: {

    sidebarOpen: {
      type: Boolean,
      required: true,
    },

    mobile: {
      type: Boolean,
      required: true,
    }

  },

  data() {
    return {

      selectedIndex: -1,
      // Placeholder menuItems:
      menuItems: [
        { title: "Option 1" },
        { title: "Option 2" },
        { title: "Option 3" },
        { title: "Option 4" },
        { title: "Option 5" },
        { title: "Option 6" }
      ],
      rotate90Degrees: true,

    };
  },

  methods: {

    selectItem(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = -1;
      } else {
        this.selectedIndex = index;
        this.rotate90Degrees = true;
      }
    },

    updateSidebarOpen() {
      this.$emit("toggleSidebarOpen");
    }

  },

}

</script>

<template>

  <!-- Side Bar -->
  <v-navigation-drawer @update:modelValue="updateSidebarOpen" :model-value="sidebarOpen" color="blue-grey-darken-3" :class="mobile ? 'mobile-drawer' : 'browser-drawer'" :permanent="!mobile" >
    <v-list density="comfortable" nav>

      <!-- Menu Options -->
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        :title="item.title"
        :class="{ 'selectedItemColor': index === selectedIndex }"
        @click="selectItem(index)"
      >
        <!-- Triangle Button for opening the Menu Options -->
        <template v-slot:append>
          <v-icon color="white" :class="{ 'rotate-90-degrees': rotate90Degrees && index !== selectedIndex }">
            mdi-triangle-small-down
          </v-icon>
        </template>

      </v-list-item>

    </v-list>
  </v-navigation-drawer>

</template>
