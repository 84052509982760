import { createApp } from "vue";

import { createVuetify } from "vuetify";
import { VBtn } from "vuetify/lib/components/index.mjs";
import { VDataTable } from "vuetify/labs/VDataTable";
import { VDatePicker } from "vuetify/labs/VDatePicker";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import App from "./App.vue";
import "vuetify/dist/vuetify.css";
import "../styles/main.css";

const app = createApp(App);

const vuetify = createVuetify({
  directives,
  components: {
    ...components,
    VBtn,
    VDataTable,
    VDatePicker,
  },
});

app.use(vuetify);

app.mount("#app");
