<script>

import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";

export default {

  components: { PieChart, BarChart },

  props: {

    locations: {
      type: Array,
      required: true,
    },

    mobile: {
      type: Boolean,
      required: true,
    },

  },

  data() {

    return {

      pieChart: true,
      barChart: false,
      fullCylindersSwitch: true,
      emptyCylindersSwitch: true,
      emptySwitch: true,
      slots: [0, 1, 2],

    }

  },

  methods: {

    showPieChart() {
      this.pieChart = true;
      this.barChart = false;
    },

    showBarChart() {
      this.barChart = true;
      this.pieChart = false;
    },

    updateSlots() {
      this.slots = [];
      if (this.fullCylindersSwitch) {
        this.slots.push(0);
      }
      if (this.emptyCylindersSwitch) {
        this.slots.push(1);
      }
      if (this.emptySwitch) {
        this.slots.push(2);
      }
    },

  },

  watch: {

    fullCylindersSwitch() {
      this.updateSlots();
    },

    emptyCylindersSwitch() {
      this.updateSlots();
    },

    emptySwitch() {
      this.updateSlots();
    },

  },

};

</script>

<template>

  <div>
    <v-card
      class="ma-4 rounded-lg"
      variant="text"
      height="100%"
    >
      <!-- Card Title -->
      <v-card-title class="charts-div d-flex align-start" :class="mobile ? 'pa-2' : 'pa-5'">
        Pallet status
        <div class="mx-4">

          <!-- Pie or Bar Chart Selection -->
          <v-btn
            rounded="lg"
            size="x-small"
            icon="mdi-chart-pie-outline"
            :class=" {
              'mx-1': mobile,
              'mx-2': !mobile,
              'selected-button': pieChart,
              'unselected-button': !pieChart
            } "
            @click="showPieChart"
          ></v-btn>

          <v-btn
            class="mx-2"
            rounded="lg"
            size="x-small"
            icon="mdi-poll"
            :class=" {
              'mx-1': mobile,
              'mx-2': !mobile,
              'selected-button': barChart,
              'unselected-button': !barChart
            } "
            @click="showBarChart"
          ></v-btn>

        </div>
      </v-card-title>

      <!-- Card Content -->
      <v-card-text class="charts-div pa-0 rounded-b-lg">
        <v-container class="py-3 px-4">

          <!-- Selection Switches -->
          <div class="d-inline-flex justify-start" :class="mobile ? 'flex-column': 'flex-row'">

            <v-switch
              v-model="fullCylindersSwitch"
              class="selection-switch"
              :class="mobile ? 'mx-1' : 'mx-2'"
              hide-details="auto"
              color="amber-lighten-2"
              density="compact"
            >
              <template v-slot:label>
                Filled with full cylinders
              </template>
            </v-switch>

            <v-switch
              v-model="emptyCylindersSwitch"
              class="selection-switch"
              :class="mobile ? 'mx-1' : 'mx-2'"
              hide-details="auto"
              color="indigo"
              density="compact"
            >
              <template v-slot:label>
                Filled with empty cylinders
              </template>
            </v-switch>

            <v-switch
              v-model="emptySwitch"
              class="selection-switch"
              :class="mobile ? 'mx-1' : 'mx-2'"
              hide-details="auto"
              color="purple"
              density="compact"
            >
              <template v-slot:label>
                Empty
              </template>
            </v-switch>

          </div>

          <div class="chart-div">
            <div v-if="pieChart">
              <PieChart :locations=locations :slots=slots />
            </div>
            <div v-if="barChart">
              <BarChart :locations=locations :slots=slots />
            </div>
          </div>

        </v-container>
      </v-card-text>

    </v-card>
  </div>

</template>
