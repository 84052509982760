<script>

import { useDisplay } from "vuetify";

export default {

  setup() {
    const { smAndDown, mdAndUp } = useDisplay();

    return {
      smAndDown,
      mdAndUp,
    }
  },

  data() {

    return {

      menuIsActive: false,
      avatarColor: "#FFD54F", /* "amber-lighten-2" from Material design color palette of Vuetify */
      rotate90Degrees: true,

      // Info placeholders:
      companyName: "Random Company",
      companyEmail: "RandomCompany@gmailcom",
      companyDescription: "Just a Random Company",
    };

  },

  computed: {

    avatarLetters() {
      const words = this.companyName.split(" ");
      let iconAbbreviation = "";

      if (words.length === 1) { // Single word company name
        iconAbbreviation = words[0][0].toUpperCase();

      } else if (words.length > 1) { // Multiple word company name
        const firstWord = words[0];
        const lastWord = words[words.length - 1];

        iconAbbreviation += firstWord[0].toUpperCase();
        iconAbbreviation += lastWord[0].toUpperCase();
      }

      return iconAbbreviation;
    },

  },

  methods: {

    closeProfile() {
      this.menuIsActive = false;
    },

    rotateButton(value) {
      this.menuIsActive = value;
      this.rotate90Degrees = true; // Rotate the triangle
    },

    toggleSidebar() {
      this.$emit("toggleSidebarOpen");
    }

  },

};

</script>

<template>

  <!-- App Bar -->
  <v-app-bar color="blue-grey-darken-3" :elevation="1" class="app-bar">

    <template v-slot:prepend>
      <v-btn v-if="smAndDown" size="20" class="ml-2" @click="toggleSidebar">
        <v-icon color="white">
          mdi-menu
        </v-icon>
      </v-btn>
      <!-- Place for the company logo -->
      <v-img
        v-if="mdAndUp"
        src="data/Logo_Placeholder.png"
        width="225"
      ></v-img>
    </template>

    <!-- Place for the company logo -->
    <v-img
      v-if="smAndDown"
      src="data/Logo_Placeholder.png"
      max-width="100"
      class="mobile-logo"
    ></v-img>

    <!-- Company Profile -->
    <template v-slot:append>
      <v-list class="d-flex align-center transparent-background">
        <v-list-item v-if="smAndDown">
          <template v-slot:append>
            <v-avatar :color="avatarColor">
              <span class="text-h6">
                {{ avatarLetters }}
              </span>
            </v-avatar>
          </template>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-title class="white-text">
            {{ companyName }}
          </v-list-item-title>
          <v-list-item-subtitle class="white-text">
            {{ companyEmail }}
          </v-list-item-subtitle>
          <template v-slot:append>
            <v-avatar class="ml-4" :color="avatarColor">
              <span class="text-h6">
                {{ avatarLetters }}
              </span>
            </v-avatar>
          </template>
        </v-list-item>

        <!-- Triangle Button for opening the Company Profile as Popup -->
        <div class="text-center">
          <v-menu
            v-model="menuIsActive"
            ref="menu"
            location="bottom" class="mt-4"
            :close-on-content-click="false"
            @update:model-value="rotateButton"
          >
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" size="15" max-width="min-content">
                <v-icon color="white" size="x-small" :class="{ 'rotate-90-degrees': rotate90Degrees && !menuIsActive }">
                  mdi-triangle-down
                </v-icon>
              </v-btn>
            </template>

            <!-- Company Profile as Popup -->
            <v-card
              min-width="260"
              class="mt-7 popup-card-background"
              variant="outlined"
              :elevation="0"
            >
              <v-list class="transparent-background">
                <v-list-item>
                  <v-list-item-title class="white-text">
                    {{ companyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="white-text">
                    {{ companyDescription }}
                  </v-list-item-subtitle>
                  <template v-slot:append>
                    <v-avatar :color="avatarColor">
                      <span class="text-h6">
                        {{ avatarLetters }}
                      </span>
                    </v-avatar>
                  </template>
                </v-list-item>
              </v-list>


              <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn variant="text" @click="closeProfile">
                  Close
                </v-btn>

              </v-card-actions>
            </v-card>

          </v-menu>
        </div>

      </v-list>
    </template>
  </v-app-bar>

</template>
