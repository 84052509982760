<script>

export default {

  props: {

    locations: {
      type: Array,
      required: true,
    },

    mobile: {
      type: Boolean,
      required: true,
    },

  },

  data() {
    return {

      page: 1,
      itemsPerPage: 4,
      headers: [
        { title: "Location", align: "start", key: "name" },
        { title: "With full cylinders", align: "start", key: "filled_full" },
        { title: "With empty cylinders", align: "start", key: "filled_empty" },
        { title: "Empty", align: "start", key: "empty" },
        { title: "Overall", align: "start", key: "overall" },
        { title: "Mean time at location", align: "start", key: "average_time" },
      ],
      collapsed: false,

    }
  },

  computed: {

    pageCount() {
      return Math.ceil(this.locations.length / this.itemsPerPage);
    },

    isFirstPage() {
      return this.page === 1;
    },

    isLastPage() {
      return this.page === this.pageCount;
    },

  },

  methods: {

    toggleCardCollapse() {
      this.collapsed = !this.collapsed;
    },

    goToFirstPage() {
      this.page = 1;
    },

    goToLastPage() {
      this.page = this.pageCount;
    },

    convertToCSV(data) {

      const csvArray = [];
      const headerLabels = this.headers.map(headerItem => headerItem.title);

      csvArray.push(headerLabels.join(","));

      data.forEach((item) => {
          const values = this.headers.map((headerItem) => item[headerItem.key]);
          csvArray.push(values.join(","));
      });

      return csvArray.join("\n");
  },

  downloadCSV() {
      const csvArray = this.convertToCSV(this.locations);
      this.$emit("downloadCSV", csvArray, "exported-locations-data.csv");
  }

  },

};

</script>

<template>

  <div>
    <v-card
      :class="mobile ? 'my-1 rounded-0' : 'mx-4 mt-2 mb-1 rounded-lg'"
      variant="text"
      height="100%"
    >
      <!-- Card Title -->
      <v-card-title class="card-title d-flex justify-space-between align-center" :class="mobile ? 'px-2 py-1 text-subtitle-1' : 'text-h6'">
        Location Table
        <div class="d-flex align-center">

          <v-btn
            class="text-end mr-2"
            density="compact"
            color="transparent"
            icon="mdi-tray-arrow-down"
            elevation="0"
            :size="mobile ? 'small' : 'default'"
            @click="downloadCSV"
          ></v-btn>

          <v-btn
            class="text-end"
            density="compact"
            color="blue-grey-darken-2"
            icon="mdi-minus"
            :size="mobile ? 'small' : 'default'"
            @click="toggleCardCollapse"
          ></v-btn>

        </div>
      </v-card-title>

      <!-- Card Content -->
      <v-card-text v-if="!collapsed" class="pa-0">
        <div class="paginated-container" :class="mobile ? 'rounded-b-0' : 'rounded-b-lg'">

          <!-- Location Table -->
          <v-data-table
            v-model:page="page"
            class="elevation-0 location-table"
            :density="mobile ? 'compact' : 'comfortable'"
            :hover=true
            :multi-sort=true
            :headers="headers"
            :items="locations"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:bottom></template>
          </v-data-table>

        </div>
      </v-card-text>

    </v-card>

    <!-- Table Pagination -->
    <div v-if="!collapsed" class="d-flex flex-row justify-end align-center">

      <v-btn
        size="small"
        variant="text"
        icon="mdi-chevron-double-left"
        :disabled="isFirstPage"
        @click="goToFirstPage"
      ></v-btn>

      <v-pagination
        v-model="page"
        class="pagination pa-0"
        rounded="circle"
        density="comfortable"
        next-icon="mdi-chevron-right"
        prev-icon="mdi-chevron-left"
        :total-visible="4"
        :length="pageCount"
      ></v-pagination>

      <v-btn
        class="ma-0 pa-0"
        size="small"
        variant="text"
        icon="mdi-chevron-double-right"
        :disabled="isLastPage"
        @click="goToLastPage"
      ></v-btn>

    </div>
  </div>

</template>
