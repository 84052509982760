<script>

import PalletTable from "./PalletTable.vue";
import LocationTable from "./LocationTable.vue";
import TableFilter from "./TableFilter.vue";
import Charts from "./Charts.vue";
import { useDisplay } from "vuetify";

export default {

  setup() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown
    }
  },

  components: { TableFilter, LocationTable, PalletTable, Charts },

  created() {
    this.fetchData();
  },

  data() {
    return {
      items: [],
      filteredItems: [],
      filters: [],
      locations: [],
      dataLoaded: false,
      chartView: false,
      tableView: true,
      showTables: true,
    };
  },

  methods: {

    showChartView() {
      this.chartView = true;
      this.tableView = false;
    },

    showTableView() {
      this.tableView = true;
      this.chartView = false;
    },

    toggleShowTables() {
      this.showTables = !this.showTables;
    },

    fetchData() {
      fetch("data/pallet_disposition_report_data.json")
        .then(response => response.json())
        .then(result => {
          this.items = result.data;
          this.filteredItems = this.items;
          this.locations = result.meta.locationStatus;

          this.loadFilters();
          this.dataLoaded = true;
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },

    filterItems(selectedFilters) {
      this.filteredItems = this.items.filter((item) => {

        // Date:
        let palletAtLocationAtDate = false;
        if (selectedFilters.date && item.time_at_location) {
          palletAtLocationAtDate = this.palletAtLocationAtGivenDate(item, selectedFilters.date);
        }

        // Serial number:
        let serialNumberContains = false;
        if (selectedFilters.serialNumber && item.serial_number) {
          serialNumberContains = item.serial_number.includes(selectedFilters.serialNumber);
        }

        // Tag:
        let tagsContain = false;
        if (selectedFilters.tag && item.tags) {
          let tags = item.tags.split(",");
          tags = tags.map((tag) => tag.trim());
          for (let tag of tags) {
            if (tag.includes(selectedFilters.tag)) {
              tagsContain = true;
            }
          }
        }

        // Location:
        let palletAtLocation = false;
        if (selectedFilters.location && item.location) {
          if (item.location === selectedFilters.location) {
            palletAtLocation = true;
          }
        }

        // Filter items:
        if (
          (palletAtLocationAtDate || !selectedFilters.date) &&
          (serialNumberContains || !selectedFilters.serialNumber) &&
          (tagsContain || !selectedFilters.tag) &&
          (palletAtLocation || !selectedFilters.location)
        ) {
          return item;
        }
      });
    },

    palletAtLocationAtGivenDate(item, date) {
      const currentDate = new Date();

      const days = this.extractDaysFromString(item.time_at_location);
      const locationArrivalDate = this.subtractDaysFromDate(currentDate, days);

      // Check if the item has been at the location at the given date
      if (item.time_at_location && locationArrivalDate <= date) {
        return true;
      }

      return false;
    },

    subtractDaysFromDate(date, daysToSubtract) {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const dateInMillis = date.getTime();
      const newDateInMillis = dateInMillis - daysToSubtract * millisecondsPerDay;
      const newDate = new Date(newDateInMillis);
      return newDate;
    },

    extractDaysFromString(str) {
      const regex = /(\d+)d/;
      const match = str.match(regex);
      if (match && match[1]) {
        return parseInt(match[1], 10);
      }
      return 0; // If no days found in the string, return 0.
    },

    clearFilters() {
      this.filteredItems = this.items;
    },

    handleCreateNewFilter(filter) {
      this.filters.push({
        name: filter.name,
        date: filter.date,
        serialNumber: filter.serialNumber,
        tag: filter.tag,
        location: filter.location,
      });

      this.saveFilters();
    },

    handleUpdateFilter(filterToUpdate) {
      const existingFilter = this.filters.find((filter) => filter.name === filterToUpdate.name);

      if (existingFilter) {
        existingFilter.date = filterToUpdate.date;
        existingFilter.serialNumber = filterToUpdate.serialNumber;
        existingFilter.tag = filterToUpdate.tag;
        existingFilter.location = filterToUpdate.location;
        this.saveFilters();
      }
    },

    handleDeleteFilter(filterToDelete) {
      const index = this.filters.findIndex((filter) => filter.name === filterToDelete.name);

      if (index !== -1) {
        this.filters.splice(index, 1);
        this.saveFilters();
      }
    },

    saveFilters() {
      const filtersJSON = JSON.stringify(this.filters);
      localStorage.setItem("savedFilters", filtersJSON);
      console.log("Filters saved to Local Storage.");
    },

    loadFilters() {
      const filtersJSON = localStorage.getItem("savedFilters");

      if (filtersJSON) {
        const savedFilters = JSON.parse(filtersJSON);
        this.filters = savedFilters;
        console.log("Filters loaded from Local Storage.");
      } else {
        console.log("No saved filters found.");
      }
    },

    downloadCSV(csvArray, filename) {
      const blob = new Blob([csvArray], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

  },

}

</script>

<template>

  <div v-if="dataLoaded">
    <div>
      <TableFilter
        :mobile="smAndDown ? true : false"
        :items=items
        :filters="filters"
        @filter="filterItems"
        @clear="clearFilters"
        @create="handleCreateNewFilter"
        @update="handleUpdateFilter"
        @delete="handleDeleteFilter"
        @toggleTables="toggleShowTables"
      />
    </div>

    <div v-if="showTables">
      <div class="mt-2 mb-2 d-flex align-center justify-center">
        <v-btn
          class="mx-1 lowercase"
          rounded="lg"
          :class=" {
            'selected-button': chartView,
            'unselected-button': !chartView,
            'mobile-button': smAndDown,
          } "
          @click="showChartView"
        >
          <v-icon
            class="mr-1"
            color="white"
            icon="mdi-chart-bar"
          ></v-icon>
          Chart view
        </v-btn>
        <v-btn
          class="mx-1 lowercase"
          rounded="lg"
          :class=" {
            'selected-button': tableView,
            'unselected-button': !tableView,
            'mobile-button': smAndDown,
          } "
          @click="showTableView"
        >
          <v-icon
            class="mr-1"
            color="white"
            icon="mdi-table-large"
          ></v-icon>
          Table view
        </v-btn>
      </div>

      <div v-if="tableView">
        <LocationTable :locations=locations :mobile="smAndDown ? true : false" @downloadCSV="downloadCSV" />
        <PalletTable :items=filteredItems :mobile="smAndDown ? true : false" @downloadCSV="downloadCSV" />
      </div>
      <div v-if="chartView">
        <Charts :locations=locations :mobile="smAndDown ? true : false" />
      </div>
    </div>

  </div>

  <div v-else>
    Loading...
  </div>

</template>
