<script>

export default {

  props: {

    items: {
      type: Array,
      required: true,
    },

    mobile: {
      type: Boolean,
      required: true,
    },

  },

  data() {
    return {

      collapsed: false,
      headers: [
        { title: "Tag", key: "tags" },
        { title: "Serial number", key: "serial_number" },
        { title: "Status", key: "status" },
        { title: "Location", key: "location" },
        { title: "Time at location", key: "time_at_location" },
      ],

    }
  },

  methods: {

    toggleCardCollapse() {
      this.collapsed = !this.collapsed;
    },

    convertToCSV(data) {
      const csvArray = [];
      const headerLabels = this.headers.map(headerItem => headerItem.title);

      csvArray.push(headerLabels.join(","));

      data.forEach((item) => {

        if (Array.isArray(item.tags)) {

          item.tags.forEach((tag) => {

            const values = this.headers.map((headerItem) => {

              if (headerItem.key === "tags") {
                return `"${tag}"`;
              }
              return item[headerItem.key];

            });

            csvArray.push(values.join(","));
          });

        } else {

          const values = this.headers.map((headerItem) => {

            if (headerItem.key === "tags") {
              return `"${item[headerItem.key]}"`;
            }
            return item[headerItem.key];

          });

          csvArray.push(values.join(","));
        }
      });

      return csvArray.join("\n");
    },

    downloadCSV() {
      const csvArray = this.convertToCSV(this.items);
      this.$emit("downloadCSV", csvArray, "exported-pallet-data.csv");
    }

  }

};

</script>

<template>

  <div>
    <v-card
      :class="mobile ? 'my-1 rounded-0' : 'mx-4 mt-2 mb-1 rounded-lg'"
      variant="text"
      height="280"
    >
      <!-- Card Title -->
      <v-card-title class="card-title d-flex justify-space-between align-center" :class="mobile ? 'px-2 py-1 text-subtitle-1' : 'text-h6'">
        Pallet Table

        <div class="d-flex align-center">

          <v-btn
            class="text-end mr-2"
            density="compact"
            color="transparent"
            icon="mdi-tray-arrow-down"
            elevation="0"
            :size="mobile ? 'small' : 'default'"
            @click="downloadCSV"
          ></v-btn>

          <v-btn
            class="text-end"
            density="compact"
            color="blue-grey-darken-2"
            icon="mdi-minus"
            :size="mobile ? 'small' : 'default'"
            @click="toggleCardCollapse"
          ></v-btn>

        </div>
      </v-card-title>

      <!-- Card Content -->
      <v-card-text v-if="!collapsed" class="pa-0">
        <div class="scrollable-container" :class="mobile ? 'rounded-b-0' : 'rounded-b-lg'">

          <!-- Pallet Table -->
          <v-table :density="mobile ? 'compact' : 'comfortable'" :hover=true>

            <thead>
              <tr>
                <th class="text-left table-header">
                  {{ this.headers[0].title }}
                </th>
                <th class="text-left table-header">
                  {{ this.headers[1].title }}
                </th>
                <th class="text-left table-header">
                  {{ this.headers[2].title }}
                </th>
                <th class="text-left table-header">
                  {{ this.headers[3].title }}
                </th>
                <th class="text-left table-header">
                  {{ this.headers[4].title }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in items" class="table-row" :key="item.id">
                <td>{{ item.tags }}</td>
                <td>{{ item.serial_number }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.location }}</td>
                <td>{{ item.time_at_location }}</td>
              </tr>
            </tbody>

          </v-table>

        </div>
      </v-card-text>

    </v-card>
  </div>

</template>
