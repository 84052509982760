<script>
  import NavBar from "./components/NavBar.vue";
  import SideBar from "./components/SideBar.vue";
  import PalletDisposition from "./components/PalletDisposition.vue";
  import { useDisplay } from "vuetify";

  export default {

    setup() {
      const { smAndDown, mdAndDown } = useDisplay();

      return {
        smAndDown,
        mdAndDown
      }
    },

    components: {
      NavBar,
      SideBar,
      PalletDisposition
    },

    data() {
      return {

        sidebarOpen: false,

      }
    },

    methods: {

      toggleSidebarOpen() {
        this.sidebarOpen = !this.sidebarOpen;
      },

    },

  }
</script>

<template>
  <v-app class="background">
    <div v-if="smAndDown">
      <NavBar @toggleSidebarOpen="toggleSidebarOpen"/>
      <SideBar :sidebarOpen="sidebarOpen" :mobile="true" @toggleSidebarOpen="toggleSidebarOpen" />
      <v-main>
        <PalletDisposition />
      </v-main>
    </div>
    <div v-else>
      <NavBar />
      <SideBar :sidebarOpen="true" :mobile="false" />
      <v-main>
        <PalletDisposition />
      </v-main>
    </div>
  </v-app>
</template>
