<script>

export default {

  props: {

    items: {
      type: Array,
      required: true,
    },

    filters: {
      type: Array,
      required: true,
    },

    mobile: {
      type: Boolean,
      required: true,
    }

  },

  created() {
    this.extractLocations();
    if (this.mobile) {
      this.collapsed = true;
    }
  },

  mounted() {
    window.addEventListener("resize", this.updateDatePickerWidth);
    this.updateDatePickerWidth();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateDatePickerWidth);
  },

  data() {
    return {
      datePicker: false,
      date: null,
      selectedDatesArray: [],
      serialNumber: "",
      tag: "",
      location: null,
      locations: [],
      selectedFilter: null,
      newFilter: "",
      showNewFilterDialog: false,
      collapsed: false,
      datePickerWidth: null,
    };
  },

  computed: {

    filtersWithNewFilterOption() {
      const filtersWithNewFilter = [...this.filters.map((filter) => filter.name), "New Filter"];
      return filtersWithNewFilter;
    },

    formattedDate() {
      if (!this.date) {
        return "";
      }
      else {
        let dateToFormat;
        if (Array.isArray(this.date)) {
          dateToFormat = this.date[0];
        }
        else {
          dateToFormat = this.date;
        }
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        const formattedDate = dateToFormat.toLocaleDateString("en-GB", options);
        return formattedDate;
      }
    },

  },

  methods: {

    updateDatePickerWidth() {
      if (window.innerWidth < 360) {
        this.datePickerWidth = window.innerWidth;
      } else {
        this.datePickerWidth = 360;
      }
    },

    handleCollapseClick() {
      this.toggleCardCollapse();
      this.$emit("toggleTables");
    },

    handleCloseClick() {
      this.filterData();
      this.toggleCardCollapse();
      this.$emit("toggleTables");
    },

    toggleCardCollapse() {
      this.collapsed = !this.collapsed;
    },

    extractLocations() {
      // Iterate through the items array
      this.items.forEach((item) => {
        const location = item.location;
        // Check if the item's location is not already present in the locations array
        if (location && !this.locations.includes(location)) {
          this.locations.push(location);
        }
      });

      // Sort the locations in alphabetical order
      this.locations.sort((a, b) => a.localeCompare(b));
    },

    closeDatePicker() {
      this.datePicker = false;
    },

    updateFilter() {
      // Check if the new filter name is not empty
      if (this.selectedFilter) {
        this.$emit("update", {
          name: this.selectedFilter,
          date: this.date,
          serialNumber: this.serialNumber,
          tag: this.tag,
          location: this.location,
        });
      }
    },

    openNewFilterDialog() {
      this.newFilter = "";
      this.showNewFilterDialog = true;
    },

    saveNewFilter() {
      // Check if the new filter name is not empty
      if (this.newFilter) {
        this.$emit("create", {
          name: this.newFilter,
          date: this.date,
          serialNumber: this.serialNumber,
          tag: this.tag,
          location: this.location,
        });
        this.selectedFilter = this.newFilter;
      }
      this.showNewFilterDialog = false;
    },

    cancelNewFilter() {
      this.selectedFilter = null;
      this.showNewFilterDialog = false;
    },

    deleteFilter() {
      if (this.selectedFilter) {
        this.$emit("delete", {
          name: this.selectedFilter,
        });
        this.selectedFilter = null;
        this.newFilter = "";
        this.clearFields();
      }
    },

    clearFields() {
      this.date = null;
      this.serialNumber = "";
      this.tag = "";
      this.location = null;
      this.$emit("clear");
    },

    filterData() {
      this.$emit("filter", {
        date: this.date,
        serialNumber: this.serialNumber,
        tag: this.tag,
        location: this.location,
        selectedFilter: this.selectedFilter,
      });
    },

    onSubmit() {
      this.filterData();
    },

  },

  watch: {

    selectedFilter(newFilter) {
      if (newFilter === "New Filter") {
        this.showNewFilterDialog = true;
      }
      else {
        const filter = this.filters.find((filter) => filter.name === newFilter);
        if (filter) {
          this.date = filter.date ? new Date(filter.date) : null;
          this.serialNumber = filter.serialNumber;
          this.tag = filter.tag;
          this.location = filter.location;
        }
        if (this.mobile) {
          this.filterData();
        }
      }
    },

    selectedDatesArray(newArray) {
      // When the selectedDatesArray changes, update the selectedDate
      this.date = newArray.length > 0 ? newArray[0] : null;
    },

  },

};

</script>

<template>

  <div>
    <div v-if="mobile">
      <v-card
        class="rounded-0"
        variant="text"
        height="100%"
      >
        <!-- Card Title -->
        <v-card-title class="card-title py-1 px-1">

          <v-row no-gutters class="d-flex justify-space-between align-center pa-0">

            <!-- Saved Filter Field -->
            <v-col cols="5" sm="3" class="mb-2">
              <v-select
                v-model="selectedFilter"
                hide-details="auto"
                placeholder="Saved filter"
                density="compact"
                variant="plain"
                :items="filtersWithNewFilterOption"
              ></v-select>
            </v-col>

            <!-- Buttons -->
            <v-col class="d-flex justify-end">
              <v-btn
                @click="updateFilter"
                rounded="lg"
                density="comfortable"
                color="white"
                variant="text"
                icon="mdi-content-save-outline"
              ></v-btn>
              <v-btn
                @click="deleteFilter"
                rounded="lg"
                density="comfortable"
                color="white"
                variant="text"
                icon="mdi-delete-outline"
              ></v-btn>
              <v-btn
                @click="clearFields"
                rounded="lg"
                density="comfortable"
                color="white"
                variant="text"
                icon="mdi-eraser"
              ></v-btn>
              <v-btn
                @click="handleCollapseClick"
                rounded="lg"
                density="comfortable"
                color="white"
                variant="text"
                icon="mdi-tune-variant"
              ></v-btn>
            </v-col>

          </v-row>

        </v-card-title>

        <!-- Card Content -->
        <v-card-text v-if="!collapsed" class="pa-0">
          <div class="filter-div">
            <v-container class="py-3 px-8">
              <v-form @submit.prevent="onSubmit">

                <v-row class="d-flex justify-space-between py-5 px-3">
                  <h2>Filter</h2>
                  <v-btn
                    @click="handleCloseClick"
                    rounded="lg"
                    density="compact"
                    color="black"
                    variant="text"
                    icon="mdi-close"
                  ></v-btn>
                </v-row>

                <!-- Date Field -->
                <v-col cols="12" class="py-0 px-1">
                  <div class="text-subtitle-1">
                    Date
                  </div>
                  <v-menu v-model="datePicker" :close-on-content-click="false">

                    <template v-slot:activator="{ attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        v-bind="attrs"
                        prepend-icon="mdi-calendar"
                        density="compact"
                        placeholder="DD/MM/YYYY"
                        variant="outlined"
                        readonly
                        @click="datePicker = !datePicker"
                      ></v-text-field>
                    </template>

                    <v-card :style="{ width: datePickerWidth + 'px !important' }" >
                      <v-date-picker
                        show-adjacent-months
                        v-model="selectedDatesArray"
                        color="blue-grey darken-4"
                        class="filter-date-picker"
                        @input="datePicker = false"
                        @click:cancel="datePicker = !datePicker"
                        @click:save="closeDatePicker"
                      ></v-date-picker>
                    </v-card>

                  </v-menu>
                </v-col>

                <!-- Serial Number Field -->
                <v-col cols="12" class="py-0 px-1">
                  <div class="text-subtitle-1">
                    Serial Number
                  </div>
                  <v-text-field
                    v-model="serialNumber"
                    density="compact"
                    variant="outlined"
                  ></v-text-field>
                </v-col>

                <!-- Tag Field -->
                <v-col cols="12" class="py-0 px-1">
                  <div class="text-subtitle-1">
                    Tag
                  </div>
                  <v-text-field
                    v-model="tag"
                    density="compact"
                    variant="outlined"
                  ></v-text-field>
                </v-col>

                <!-- Location Field -->
                <v-col cols="12" class="py-0 px-1">
                  <div class="text-subtitle-1">
                    Location
                  </div>
                  <v-select
                    v-model="location"
                    density="compact"
                    variant="outlined"
                    :items="locations"
                  ></v-select>
                </v-col>

                <!-- Dialog for New Filter -->
                <v-dialog v-model="showNewFilterDialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">New Filter</span>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field v-model="newFilter" label="Filter Name"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="saveNewFilter" color="primary">Save</v-btn>
                      <v-btn @click="cancelNewFilter" color="primary">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-form>
            </v-container>
          </div>
        </v-card-text>

      </v-card>
    </div>

    <div v-else>
      <v-card
        class="mx-4 mt-4 mb-2 rounded-lg"
        variant="text"
        height="100%"
      >
        <!-- Card Title -->
        <v-card-title class="card-title d-flex justify-space-between">
          Filter
          <v-btn
            class="text-end"
            density="compact"
            color="blue-grey-darken-2"
            icon="mdi-minus"
            @click="toggleCardCollapse"
          ></v-btn>
        </v-card-title>

        <!-- Card Content -->
        <v-card-text v-if="!collapsed" class="pa-0">
          <div class="filter-div rounded-b-lg">
            <v-container class="py-3 px-8">
              <v-form @submit.prevent="onSubmit">

                <!-- Start of the First Row -->
                <v-row class="mt-0">

                  <!-- Date Field -->
                  <v-col cols="12" sm="4" class="py-0 px-1">
                    <div class="text-subtitle-1">
                      Date
                    </div>
                    <v-menu v-model="datePicker" :close-on-content-click="false">

                      <template v-slot:activator="{ attrs }">
                        <v-text-field
                          v-model="formattedDate"
                          v-bind="attrs"
                          prepend-icon="mdi-calendar"
                          density="compact"
                          placeholder="DD/MM/YYYY"
                          variant="outlined"
                          readonly
                          @click="datePicker = !datePicker"
                        ></v-text-field>
                      </template>

                      <v-card>
                        <v-date-picker
                          show-adjacent-months
                          v-model="selectedDatesArray"
                          color="blue-grey darken-4"
                          class="filter-date-picker"
                          @input="datePicker = false"
                          @click:cancel="datePicker = !datePicker"
                          @click:save="closeDatePicker"
                        ></v-date-picker>
                      </v-card>

                    </v-menu>
                  </v-col>

                  <!-- Serial Number Field -->
                  <v-col cols="12" sm="4" class="py-0 px-1">
                    <div class="text-subtitle-1">
                      Serial Number
                    </div>
                    <v-text-field
                      v-model="serialNumber"
                      density="compact"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>

                </v-row>

                <!-- Start of the Second Row -->
                <v-row class="mt-0">

                  <!-- Tag Field -->
                  <v-col cols="12" sm="4" class="py-0 px-1">
                    <div class="text-subtitle-1">
                      Tag
                    </div>
                    <v-text-field
                      v-model="tag"
                      density="compact"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>

                  <!-- Location Field -->
                  <v-col cols="12" sm="4" class="py-0 px-1">
                    <div class="text-subtitle-1">
                      Location
                    </div>
                    <v-select
                      v-model="location"
                      density="compact"
                      variant="outlined"
                      :items="locations"
                    ></v-select>
                  </v-col>

                </v-row>

                <!-- Start of the Third Row -->
                <v-row class="d-flex align-center mt-0">

                  <!-- Saved Filter Field -->
                  <v-col cols="12" sm="3" class="py-0 px-1">
                    <div class="text-subtitle-1">
                      Saved filter
                    </div>
                    <v-select
                      v-model="selectedFilter"
                      density="compact"
                      variant="outlined"
                      :items="filtersWithNewFilterOption"
                    ></v-select>
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="9" class="py-0 px-1">
                    <v-btn @click="updateFilter" rounded="lg" class="update-filter lowercase mx-1">
                      Update Filter
                    </v-btn>
                    <v-btn @click="deleteFilter" rounded="lg" class="delete-filter lowercase mx-1">
                      Delete Filter
                    </v-btn>
                    <v-btn @click="clearFields" rounded="lg" class="clear-filter lowercase mx-1">
                      Clear
                    </v-btn>
                    <v-btn @click="filterData" rounded="lg" class="apply-filter lowercase mx-1">
                      Filter
                    </v-btn>
                  </v-col>

                  <!-- Dialog for New Filter -->
                  <v-dialog v-model="showNewFilterDialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">New Filter</span>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field v-model="newFilter" label="Filter Name"></v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveNewFilter" color="primary">Save</v-btn>
                        <v-btn @click="cancelNewFilter" color="primary">Cancel</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-row>

              </v-form>
            </v-container>
          </div>
        </v-card-text>

      </v-card>
    </div>
  </div>

</template>
